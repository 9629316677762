<template>
  <div>
    <v-card v-if="schoolData">
      <v-card-title>
        <span class="accent--text font-weight-bold ma-auto">{{
          schoolData.name
        }}</span>
      </v-card-title>
      <v-card-text>
        <div class="mb-4">
          <v-img
            contain
            max-height="80"
            max-width="80"
            :src="schoolMedalSrc"
            class="mx-auto"
          >
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col cols="auto">
                <div class="pb-5 accent--text font-weight-bold text-h5">
                  {{ schoolData.ranking }}
                </div>
              </v-col>
            </v-row>
          </v-img>
        </div>
        <div>
          <div>校園總用電</div>
          <div>
            <span class="accent--text font-weight-bold">{{
              schoolData.energy
            }}</span>
            <span class="ml-1">kWh</span>
          </div>
        </div>
        <div class="mt-3">
          <div>校園同期節電量</div>
          <div>
            <span class="accent--text font-weight-bold">{{
              schoolData.saving
            }}</span>
            <span class="ml-1">kWh</span>
          </div>
        </div>
        <div class="mt-3">
          <div>總需量</div>
          <div>
            <span class="accent--text font-weight-bold">{{
              schoolData.demand
            }}</span>
            <span class="ml-1">kWh</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolData: {
      type: Object
    }
  },
  data: function () {
    return {
      medalsIcons: {
        1: require('@/assets/image/medals/1.svg'),
        2: require('@/assets/image/medals/2.svg'),
        3: require('@/assets/image/medals/3.svg'),
        normal: require('@/assets/image/medals/normal.svg')
      }
    }
  },
  computed: {
    schoolMedalSrc() {
      if (!this.schoolData || !this.schoolData.ranking) {
        return this.medalsIcons['normal']
      }
      return Number(this.schoolData.ranking) >= 4
        ? this.medalsIcons['normal']
        : this.medalsIcons[Number(this.schoolData.ranking)]
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style></style>
