<template>
  <v-card class="mt-3" color="#1a5878" rounded="xl">
    <v-card-text>
      <v-row align="center">
        <v-col cols="auto">
          <div class="pl-3" style="color: #d6e1f2">
            預測本校 {{ moment().format('M') }} 月排名
          </div>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-img
            max-height="80"
            max-width="80"
            src="@/assets/image/Mask Group 10.svg"
            class="mx-auto"
          >
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col cols="auto">
                <div
                  v-if="ranking"
                  class="white--text font-weight-bold text-h4"
                >
                  {{ ranking }}
                </div>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Competition from '@/api/ems/Competition'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      ranking: null
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {
    this.schoolPredict()
  },
  destroyed() {},
  methods: {
    schoolPredict() {
      Competition.schoolPredict(this.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        this.ranking = response.data
      })
    }
  }
}
</script>

<style></style>
