<template>
  <div>
    <div ref="googleMapApp" class="googleMap" />
  </div>
</template>

<script>
import gmapsInit from '@/shared/googleMap/gmaps'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    mapZoom: {
      type: Number,
      default: 15
    },
    mapCenter: {
      type: Object,
      default: () => ({
        lat: 25.0771361,
        lng: 121.5548753
      })
    },
    schoolRanking: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      map: null,
      schoolIcon: require('@/assets/image/Component 24 – 22.svg'),
      markers: [],
      openInfowindow: null
    }
  },
  computed: {},
  watch: {
    schoolRanking() {
      this.initMap()
    }
  },
  created: function () {},
  mounted: function () {
    this.initMap()
  },
  destroyed() {},
  methods: {
    async initMap() {
      try {
        const google = await gmapsInit()
        this.map = new google.maps.Map(this.$refs.googleMapApp, {
          zoom: this.mapZoom,
          center: {
            lat: Number(this.mapCenter.lat),
            lng: Number(this.mapCenter.lng)
          },
          streetViewControl: false,
          FullscreenControlOptions: false,
          mapTypeControl: false,
          scaleControl: false
        })
        this.makeMark()
      } catch (error) {
        console.error(error)
      }
    },
    makeMark() {
      this.removeMarker()
      this.schoolRanking.map((marker) => {
        const thisMarker = new google.maps.Marker({
          icon: this.schoolIcon,
          map: this.map,
          position: {
            lng: Number(marker.longitude),
            lat: Number(marker.latitude)
          }
        })

        const Infowindow = new google.maps.InfoWindow({
          // disableAutoPan: true,
          minWidth: 150,
          position: new google.maps.LatLng(-34, 151),
          content: `
            <div class="title">${marker.name}</div>
            <div class="mt-1">
              <div>校園總用電</div>
              <div>
                <span class="secondary--text font-weight-bold">${marker.energy}</span>
                <span class="secondary--text ml-1 font-weight-bold">kWh</span>
              </div>
            </div>
            <div class="mt-1">
              <div>校園同期節電量</div>
              <div>
                <span class="secondary--text font-weight-bold">${marker.saving}</span>
                <span class="secondary--text ml-1 font-weight-bold">kWh</span>
              </div>
            </div>
            <div class="mt-1 mb-2">
              <div>總需量</div>
              <div>
                <span class="secondary--text font-weight-bold">${marker.demand}</span>
                <span class="secondary--text ml-1 font-weight-bold">kWh</span>
              </div>
            </div>
          `
        })
        thisMarker.addListener('click', () => {
          if (this.openInfowindow) {
            this.openInfowindow.close()
          }
          Infowindow.open(this.map, thisMarker)
          this.openInfowindow = Infowindow
        })
        this.markers.push(thisMarker)

        return thisMarker
      })
    },
    removeMarker() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
        google.maps.event.clearInstanceListeners(this.markers[i])
      }
      this.markers = []
    }
  }
}
</script>

<style lang="scss" scoped>
.googleMap::v-deep {
  height: 340px;
  width: 100%;

  .gm-style-iw,
  .gm-style-iw-d {
    max-height: 300px !important;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style-iw-c {
    background-color: #00000077;
    color: white;
    border-radius: 3px;
  }
  .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      #0000003a 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: none;
  }
}
</style>
