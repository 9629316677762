<template>
  <div class="comparison-index pa-4">
    <v-row>
      <v-col cols="12" md="4">
        <classRank :schoolId="tagId" />
      </v-col>
      <v-col cols="12" md="8">
        <energyUsed :schoolId="tagId" />
        <v-row>
          <v-col cols="12" md="8">
            <v-row align="end">
              <v-col cols="auto" class="pb-1">
                <v-toolbar-title
                  >{{
                    moment().subtract(1, 'month').format('M')
                  }}月各校節電排行榜</v-toolbar-title
                >
              </v-col>
              <v-col cols="auto" class="ml-auto pb-1">
                <router-link
                  v-if="pageData && pageData.districtId"
                  class="text-decoration-none"
                  :to="`/dist/${pageData.districtId}/overview`"
                  >查詢本區域各級學校 &#10132;</router-link
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="auto">
                <selfSchool :schoolData="selfSchoolData" />
              </v-col>
              <v-col>
                <schoolMap
                  :schoolRanking="schoolRanking"
                  :mapCenter="
                    schoolRanking[0]
                      ? {
                          lat: schoolRanking[0].latitude,
                          lng: schoolRanking[0].longitude
                        }
                      : {
                          lat: 0,
                          lng: 0
                        }
                  "
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <schoolRank :schoolRanking="schoolRanking" />
            <schoolForecastCard :schoolId="tagId" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import classRank from './classRank'
import schoolRank from './schoolRank'
import energyUsed from './energyUsed'
import selfSchool from './selfSchool'
import schoolMap from './schoolMap'
import schoolForecastCard from './schoolForecastCard'
import Competition from '@/api/ems/Competition'

export default {
  name: undefined,
  mixins: [],
  components: {
    classRank,
    schoolRank,
    energyUsed,
    selfSchool,
    schoolMap,
    schoolForecastCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      schoolRanking: []
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData'
    }),
    selfSchoolData() {
      const selfSchool = this.schoolRanking.find(
        (school) => school.id === this.tagId
      )
      return selfSchool
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getDistrictSchoolRanking()
  },
  destroyed() {},
  methods: {
    getDistrictSchoolRanking() {
      Competition.school(this.tagId).then((response) => {
        if (!response.data) {
          return
        }
        this.schoolRanking = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.comparison-index::v-deep {
  .v-toolbar__title {
    color: var(--v-accent-base);
    font-weight: bold;
  }
  .v-toolbar__content {
    padding: 0;
  }
}
</style>
