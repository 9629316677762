<template>
  <div>
    <v-toolbar color="transparent" class="elevation-0" dense>
      <v-toolbar-title>本校同期累計電度比較</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn-toggle
        dense
        v-model="dateType"
        color="primary"
        background-color="transparent"
        mandatory
      >
        <v-btn text value="yearly"> 年 </v-btn>
        <v-btn text value="monthly"> 月 </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-select
        v-model="meterType"
        :items="meterOptions"
        label="請選擇電表"
        class="elevation-0"
        style="width: 130px"
        solo
        flat
        dense
        hide-details
        @change="getData()"
      ></v-select>
    </v-toolbar>
    <div class="my-4" style="height: 300px">
      <Echart :data="chartData" :no-data="!filterData" />
    </div>
  </div>
</template>

<script>
import Echart from '@/components/Echart'
import Competition from '@/api/ems/Competition'

export default {
  name: undefined,
  mixins: [],
  components: {
    Echart
  },
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      dateType: 'monthly',
      meterType: 'TOTALMETER',
      meterOptions: [
        {
          text: '新設冷氣總表',
          value: 'ACMETER'
        },
        {
          text: '學校總表',
          value: 'SCHOOLMETER'
        },
        {
          text: '再生能源總表',
          value: 'SOLARMETER'
        },
        {
          text: '總用電',
          value: 'TOTALMETER'
        }
      ],
      data: null
    }
  },
  computed: {
    chartData() {
      return {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartCategory,
          axisLabel: {
            color: '#889bb7'
          },
          offset: 5,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#b4c6dd'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96abc9'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#889bb7',
            formatter: '{value}kWh'
          },
          offset: 5,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96abc9'
            }
          }
        },
        legend: {
          data: this.chartLegendData,
          left: 100
        },
        grid: {
          left: '3%',
          right: '4%',
          top: 40,
          bottom: '3%',
          containLabel: true
        },
        series: this.chartSeries,
        dataZoom: false
      }
    },
    chartCategory() {
      if (!this.filterData) {
        return
      }

      const category = []
      const findfirstDate = this.filterData[this.dateType][0]
      const dataLength =
        this.dateType === 'yearly'
          ? 12
          : moment(findfirstDate.name).endOf('month').get('date')

      for (let index = 1; index <= dataLength; index++) {
        category.push(index)
      }

      return category
    },
    chartLegendData() {
      if (!this.filterData) {
        return
      }
      return this.filterData[this.dateType].map((item) => item.name)
    },
    chartSeries() {
      if (!this.filterData) {
        return
      }
      return this.filterData[this.dateType].map((item) => {
        const data = new Array(30)
        item.items.forEach((itemData) => {
          const dataSort =
            this.dateType === 'yearly'
              ? moment(itemData.timestamp).get('month')
              : moment(itemData.timestamp).get('date') - 1
          data[dataSort] = itemData.value
        })
        return {
          name: item.name,
          type: 'line',
          data: data,
          markArea: {
            silent: true,
            data: [
              [
                {
                  xAxis: 0
                },
                {
                  xAxis: 30
                }
              ]
            ],
            itemStyle: {
              color: '#FFFFFF55'
            }
          }
        }
      })
    },
    filterData() {
      if (!this.data) {
        return
      }
      const findMeter = this.data.find((item) => item.type === this.meterType)
      return findMeter
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getData()
  },
  destroyed() {},
  methods: {
    getData() {
      Competition.schoolEnergy(this.schoolId, this.query).then((response) => {
        if (!response.data) {
          return
        }
        this.data = response.data
      })
    }
  }
}
</script>

<style></style>
