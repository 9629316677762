<template>
  <div class="school-ranking">
    <v-row>
      <v-col cols="auto" class="ml-auto pb-0">
        <schoolTypeFilterCard v-model="query" />
      </v-col>
      <v-col cols="12">
        <v-list class="pa-0 transparent">
          <v-sheet
            v-if="!filterSchoolRanking.length"
            class="d-flex justify-center align-center ems-blue-grey--text"
            color="ems-light-grey"
            height="100%"
          >
            <div>( 無資料 )</div>
          </v-sheet>
          <v-list-item
            v-for="(item, i) in filterSchoolRanking"
            :key="i"
            :class="`blue lighten-4 mb-3 rounded item-sort-${item.ranking}`"
          >
            <v-list-item-icon>
              <v-avatar
                :color="item.ranking <= 3 ? 'primary' : 'grey'"
                size="1.5rem"
              >
                <span class="white--text">{{ item.ranking }}</span>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="accent--text font-weight-bold">{{
                item.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import schoolTypeFilterCard from '@/components/schoolTypeFilterCard'

export default {
  name: undefined,
  mixins: [],
  components: {
    schoolTypeFilterCard
  },
  props: {
    schoolRanking: {
      type: Array
    }
  },
  data: function () {
    return {
      query: {
        type: []
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolType: 'config/schoolType'
    }),
    filterSchoolRanking() {
      let items = this.schoolRanking
      items = items.filter((item) => this.query.type.includes(item.type))
      return items
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.query.type = this.schoolType.map((type) => type.key)
  },
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.school-ranking::v-deep {
  .v-list {
    height: 260px;
    overflow-y: auto;
  }

  .item-sort-1 {
    background-color: #00ddcb !important;
  }
}
</style>
